import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SentryLink } from "apollo-link-sentry";
import merge from "deepmerge";
import { useMemo } from "react";

import { assertString } from "lib/util/assert-type";

let apolloClient: Record<string, ApolloClient<unknown>> = {};

function createApolloClient(token: string) {
  const { HttpLink } = require("@apollo/client/link/http");
  const headers =
    token != undefined && token === ""
      ? {}
      : { Authorization: `Bearer ${token}` };

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([
      new SentryLink({
        breadcrumb: {
          includeCache: true,
          includeVariables: true,
          includeResponse: true,
          includeError: true,
          includeQuery: true,
        },
      }),
      new HttpLink({
        uri: assertString(
          process.env.NEXT_PUBLIC_BACKEND,
          "process.env.NEXT_PUBLIC_BACKEND"
        ),
        headers,
      }),
    ]),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, token: string) {
  const _apolloClient = apolloClient[token] ?? createApolloClient(token);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient[token] = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any, token: null | string) {
  const store = useMemo(() => initializeApollo(initialState, token ?? ""), [
    initialState,
    token,
  ]);
  return store;
}
