import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/node";
import { AnimateSharedLayout } from "framer-motion";
import { NextWebVitalsMetric } from "next/app";
import Head from "next/head";

import { useApollo } from "../apollo/client";

import { useAuth } from "lib/auth";

import "rc-slider/assets/index.css";
import "../styles/app.css";
import "reactjs-popup/dist/index.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

export default function App({
  Component,
  pageProps,
  err,
  router,
}): JSX.Element {
  Sentry.configureScope((scope) => {
    // See https://www.npmjs.com/package/@sentry/node
    // Only track meaningful data from router, as it contains lots of noise
    scope.setContext("router", {
      route: router.route,
      pathname: router.pathname,
      query: router.query,
      asPath: router.asPath,
    });
  });

  const { token } = useAuth();
  const apolloClient = useApollo(pageProps.initialApolloState, token);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AnimateSharedLayout>
        <Component {...pageProps} err={err} />
      </AnimateSharedLayout>
    </ApolloProvider>
  );
}
export function reportWebVitals(metric: NextWebVitalsMetric): void {
  // eslint-disable-next-line no-console
}
