import jwtDecode from "jwt-decode";
import { useMemo } from "react";
import useCookie from "react-use-cookie";
import { UserRole } from "../graphql/generated";

type AuthHooks = {
  setToken: (token: string) => void;
  removeToken: () => void;
};

type AuthWithNoSession = AuthHooks & {
  session: null;
  token: null;
};

export type UserSession = {
  id: string;
  email: string;
  role: UserRole;
  emailVerify: boolean;
};

type AuthWithSession = AuthHooks & {
  session: UserSession;
  token: string;
};

export type AuthSession = AuthWithNoSession | AuthWithSession;

export function useAuth(): AuthSession {
  return typeof window === "undefined"
    ? {
        setToken: () => {},
        removeToken: () => {},
        token: null,
        session: null,
      }
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useClientSideAuth();
}

function useClientSideAuth(): AuthSession {
  const [userToken, setUserToken] = useCookie("token", null as any);
  // const [user2Token, setUser2Token] = useCookie("tokencds", null as any);

  const decoded = useMemo<null | UserSession>(() => {
    if (userToken) {
      try {
        const decoded = jwtDecode(userToken) as any;
        const expires = new Date(decoded.exp * 1000);
        if (new Date() >= expires) {
          throw new Error("Expired Token");
        }
        return decoded;
      } catch {
        setUserToken(null as any);
      }
    }
    return null;
  }, [setUserToken, userToken]);
  return {
    token: userToken || null,
    session: decoded || null,
    setToken: (token) => {
      setUserToken(token);
    },
    removeToken: () => setUserToken(null as any),
  } as AuthSession;
}
