import isString from "lodash/isString";

import { safeStringify } from "./safe-stringify";

export function assertString<T>(maybeString: T, debugInfo: any): string {
  if (isString(maybeString)) return maybeString as any as string;
  throw new Error(
    `Value ${safeString(maybeString)} is not a string \n ${safeString(
      debugInfo || null
    )}`
  );
}

function safeString(o: any): string {
  try {
    return JSON.stringify(o);
  } catch {
    return "" + o;
  }
}

export function assertNonNull<T>(value: T, debugInfo: any): NonNullable<T> {
  if (value == undefined) {
    const message = `Expected 'val' to be defined, but received ${value}`;
    const errorMessage = `${message}\nDebug info: ${safeStringify(debugInfo)}`;
    throw new Error(errorMessage);
  }
  return value as NonNullable<T>;
}
