export function safeStringify<T extends unknown>(toString: T): string {
  if (typeof toString === "string") return toString;
  try {
    try {
      return JSON.stringify(toString);
    } catch {
      return "Error";
    }
  } catch {
    return "unknown";
  }
}
